<template>
  <v-container>
    <v-card tile class="px-10 py-10 rounded">
      <router-view></router-view>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
</style>
